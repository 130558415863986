import { Component, ErrorInfo, ReactNode } from "react";
import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link, useLocation, Routes, Route, useNavigate  } from "react-router-dom";
import NotFound from "./NotFound";
import Header from "features/Header/Header";

interface Props {
  children?: ReactNode;
  hasError?: boolean,
  setHasError: any
}
interface State {
  hasError: boolean;
}
const ErrorComponent = () => {
  // const navigate = useNavigate();

  // // useEffect(() => {
  // //   navigate('/error');
  // // });
  return <NotFound/>;
};

// export class ErrorBoundary extends Component<Props, State> {

//   public static getDerivedStateFromError(_: Error): State {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//     console.error("Uncaught error:", error, errorInfo);
//   }

//   render() {
//     const { hasError } = this.state;
//     const { children } = this.props;

//     return hasError ? <ErrorComponent /> : children;
//   }
// };



/**
 * NEW: The error boundary has a function component wrapper.
 */
export function ErrorBoundary({children}) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);
  return (
    /**
     * NEW: The class component error boundary is now
     *      a child of the functional component.
     */
    <ErrorBoundaryInner 
      hasError={hasError} 
      setHasError={setHasError}
    >
      {children}
    </ErrorBoundaryInner>
  );
}

/**
 * NEW: The class component accepts getters and setters for
 *      the parent functional component's error state.
 */
class ErrorBoundaryInner extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, _previousState) {
    if(!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.setHasError(true);
  }

  render() {
    return this.state.hasError
      ? <ErrorComponent />
      : this.props.children; 
  }
}
