import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGetCategoryQuery } from 'services/api/api';
import { ViewportContext } from 'app/viewportContext';
import { Category } from 'services/api/types/Category';
import LoadingBlock from 'shared/LoadingBlock';
import { SearchForm } from 'features/Search/SearchForm';


const MenuItem = ({el, startUrl}: {el: Category, startUrl: string}) => {
  const location = useLocation();
  const url = `${startUrl}/${el.slug}`;
  const isActive = location.pathname.includes(url);
  const [open, setOpen] = useState(isActive);

  useEffect(() => {
    setOpen(isActive);
  }, [location]);

  const showChildMenu = (e) => {
    e.preventDefault();
    setOpen(state => !state);
  };
  return (
    <li className={el.children.length ? 'submenu' : ''}>
      <NavLink 
        to={url} 
        itemProp="url" 
        className={open? 'open' : ''} 
      >
        {el.name}
        {(el.children.length > 0) && <span className="arrow" onClick={showChildMenu}></span>}
      </NavLink>
      {(el.children.length) ? 
        <ul className={open? 'open' : ''}>{childrenList(el.children, url)}</ul>
      : ''}
    </li>
  )
};
const childrenList = (data: Category[], startUrl: string): React.ReactNode => {
  return [...data].map(el => {
    return (
      <MenuItem el={el} key={el.id} startUrl={startUrl}/>
    );
  });
};
const AsideMenu = () => {
  const breakpoint = 768;
  const width = useContext(ViewportContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const {data, isLoading} = useGetCategoryQuery();
  const [isShow, setIsShow] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsMobile(width <= breakpoint);
  }, [width]);

  useEffect(() => {
    setIsShow(false);
  }, [location]);

  const listItems = data 
    ?   data.categories.map(el => <MenuItem el={el} key={el.id} startUrl=''/>)
    :   null;

  return (
    <aside>
      <SearchForm />
      <button className="catalog__btn" onClick={() => setIsShow(state => !state)}><span>Разделы каталога</span></button>
      <LoadingBlock isLoading={isLoading}>
        {(!isMobile || (isShow && isMobile)) && 
          <ul>
            {listItems}
          </ul> 
        }
      </LoadingBlock>
    </aside>
  )
};

export default AsideMenu;