import { NavLink } from 'react-router-dom';
import MenuCatalog from './MenuCatalog';
import { useGetCategoryQuery } from 'services/api/api';

const Menu = () => {
  const {data, error, isLoading} = useGetCategoryQuery();
  const entranceDoorsCategoryId = data && data.categories.filter(el => el.name === "Входные двери")[0]?.id;
  const interiorDoorsCategoryId = data && data.categories.filter(el => el.name === "Межкомнатные двери")[0]?.id;
  const partitionCategoryId = data && data.categories.filter(el => el.name === "Раздвижные перегородки")[0]?.id;

  return (
    <ul className="menu container" itemScope itemType="http://schema.org/SiteNavigationElement">
      <li><NavLink to="/" itemProp="url">Главная</NavLink></li>
      {error ? (
        <>Ошибка</>
      ) : isLoading ? (
        <>Загрузка...</>
      ) : data ? (
        <>
          <li>
            <NavLink to="/vhodnye-dveri" itemProp="url">Входные двери</NavLink>
            {data.categoriesMap[entranceDoorsCategoryId]?.children.length > 0 && <MenuCatalog startElId={entranceDoorsCategoryId} addedClass=""/>}
          </li>
          <li>
            <NavLink to="/mezhkomnatnye-dveri" itemProp="url">Межкомнатные двери</NavLink>
            {data.categoriesMap[interiorDoorsCategoryId]?.children.length > 0 && <MenuCatalog startElId={interiorDoorsCategoryId} addedClass="menu__lvl2--col3"/>}
          </li>
          <li>
            <NavLink to="/razdvizhnye-peregorodki" itemProp="url">Раздвижные перегородки</NavLink>
            {data.categoriesMap[partitionCategoryId]?.children.length > 0 && <MenuCatalog startElId={partitionCategoryId} addedClass="menu__lvl2--col1"/>}
          </li>
          <li><NavLink to="/furnitura" itemProp="url">Фурнитура</NavLink></li>
          <li><NavLink to="/rasprodazha" itemProp="url">Распродажа</NavLink></li>
        </>
      ) : null}
      <li><NavLink to="/kontakty" itemProp="url">Контакты</NavLink></li>
    </ul>
  );
};

export default Menu;