import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Category, CategoryStore } from './types/Category';
import { Product, ProductList } from './types/Product';
import { Order } from './types/Order';
import { FormRequest } from './types/FormRequest';
import { MainPage } from './types/TextPage';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/`,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getCategory: builder.query<CategoryStore, void>({
      query: () => `categories/`,
      transformResponse: (response: Category[]) => {
        let obj: CategoryStore['categoriesMap']  = {};
        const createMap = (el: Category) => {
          obj[el.id] = el;
          el.children.forEach(el => createMap(el));
        };
        response.forEach(createMap);
        return {
          categories: response,
          categoriesMap: obj
        };
      }
    }),
    getCategoryByName: builder.query<Category, string>({
      query: (slug) => `categories/${slug}/`,
    }),
    getProductsList: builder.query<ProductList, {category_slug?: string, is_sale?: 'True'|'False', limit?: number, offset?: number, name?: string }>({
      query: (params) => {
        return {
          url: `products/`,
          params,
        };
      }
    }),
    getProductByName: builder.query<Product, string>({
      query: (slug) => `products/${slug}/`
    }),
    getSubProductsList: builder.query<Array<any>, string>({
      query: (slug) => `categories/subproducts${slug}/`
    }),
    createFormRequest: builder.mutation<FormRequest, { body: Order}>({
      query: ({body}) => ({
        url: `create_question/`,
        method: 'POST',
        body,
      })
    }),
    createCallFormRequest: builder.mutation<FormRequest, { body: FormRequest}>({
      query: ({body}) => ({
        url: `create_measurement_request/`,
        method: 'POST',
        body,
      })
    }),
    getMainPage: builder.query<MainPage, void>({
      query: () => `main_page_texts/`,
      transformResponse: (response: MainPage[]) => {
        return response[0]
      }
    }),
  }),
});

export const { 
  useGetCategoryQuery, useGetCategoryByNameQuery,
  useGetProductsListQuery, useGetProductByNameQuery, useGetSubProductsListQuery,
  useCreateFormRequestMutation, useCreateCallFormRequestMutation,
  useGetMainPageQuery
} = api;