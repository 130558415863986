import { useEffect, useRef, useState } from 'react';
import HeadTags from '../../shared/HeadTags';
import { useNavigate, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import partition_1 from 'assets/images/partition_1.jpg';
import partition_2 from 'assets/images/partition_2.jpg';
import partition_3 from 'assets/images/partition_3.jpg';
import partition_4 from 'assets/images/partition_4.jpg';
import partition_5 from 'assets/images/partition_5.jpg';
import partition_6 from 'assets/images/partition_6.jpg';
import partition_7 from 'assets/images/partition_7.png';
import partition_8 from 'assets/images/partition_8.png';
import partition_9 from 'assets/images/partition_9.png';
import partition_10 from 'assets/images/partition_10.png';
import partition_11 from 'assets/images/partition_11.png';
import partition_12 from 'assets/images/partition_12.png';
import partition_13 from 'assets/images/partition_13.png';
import partition_14 from 'assets/images/partition_14.jpg';
import partition_15 from 'assets/images/partition_15.jpg';
import partition_16 from 'assets/images/partition_16.jpg';
import partition_17 from 'assets/images/partition_17.jpg';
import partition_18 from 'assets/images/partition_18.jpg';
import partition_19 from 'assets/images/partition_19.png';
import partition_20 from 'assets/images/partition_20.jpg';
import partition_21 from 'assets/images/partition_21.jpg';
import partition_22 from 'assets/images/partition_22.jpg';
import partition_23 from 'assets/images/partition_23.jpg';
import partition_list_1 from 'assets/images/partition_list_1.jpg';
import partition_list_2 from 'assets/images/partition_list_2.jpg';
import partition_list_3 from 'assets/images/partition_list_3.jpg';
import partition_list_4 from 'assets/images/partition_list_4.jpg';
import partition_list_5 from 'assets/images/partition_list_5.jpg';
import partition_list_6 from 'assets/images/partition_list_6.jpg';
import partition_list_7 from 'assets/images/partition_list_7.jpg';
import partition_list_8 from 'assets/images/partition_list_8.jpg';
import partition_list_9 from 'assets/images/partition_list_9.jpg';
import partition_list_10 from 'assets/images/partition_list_10.jpg';
import ym from 'react-yandex-metrika';

const sliderImages = [
	require('assets/images/profile_slider_1.jpg'),
    require('assets/images/profile_slider_2.jpg'),
    require('assets/images/profile_slider_3.jpg'),
    require('assets/images/profile_slider_4.jpg'),
    require('assets/images/profile_slider_5.jpg')
];

const PartitionPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        ym('hit', `/razdvizhnye-peregorodki`);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);
    return (
        <>
            <HeadTags
                title='Раздвижные перегородки - цена в Орле компания Комфорт+'
                metaDescription='В компании Комфорт+ Вы можете купить раздвижные перегородки для зонирования пространства в любом помещении. Выбрать варианты цвета и остекления.'
            />
            <div className="entrance_top">
                <section className="container">
                    <h1>Раздвижные перегородки</h1>
                    <p>Раздвижные перегородки — способ зонирования пространства, который подойдет, как для больших комнат в домах, так и для маленьких квартир. При широких, нестандартных проемах, например, в «хрущевках» — перегородки закроют чрезмерное пространство входа в комнату, сделают помещение визуально оформленным и лаконичным, при этом не займут пространство за счет подвижных створок. Экономия места — преимущество и для небольших квартир. Однокомнатное жилье с помощью зонирования перегородкой можно разделить на спальню и гостиную или организовать детскую.</p>
                </section>
            </div>
            <section className="entrance_rubric entrance_rubric--partition container">
                <h2>Раздвижные перегородки для зонирования пространства</h2>
                <p>Раздвижные перегородки изготавливаются по индивидуальным замерам и проектам. Ширина двери может быть от 30 см до 150 см, высота до 330 см.<br />В частных домах используются раздвижные перегородки угловые, чтобы создать отдельную комнату, например гардеробную. По такой же технологии можно сделать вместительный шкаф или оформить гардеробную.</p>
                <p>Способов открывания раздвижных перегородок несколько: в стену, вдоль стены, с телескопическим механизмом и стационарным. В конструкции может быть от 1 до 6 дверей, а верхнее крепление без напольной направляющей не создает дополнительных препятствий на полу.</p>
                <ul className="rubric__list rubric__list--item5">
                    <li><img src={partition_1} alt="Раздвижные перегородки 1" title="Раздвижные перегородки" /></li>
                    <li><img src={partition_2} alt="Раздвижные перегородки 2" title="Раздвижные перегородки" /></li>
                    <li><img src={partition_3} alt="Раздвижные перегородки 3" title="Раздвижные перегородки" /></li>
                    <li><img src={partition_4} alt="Раздвижные перегородки 4" title="Раздвижные перегородки" /></li>
                    <li><img src={partition_5} alt="Раздвижные перегородки 5" title="Шпонированные" /></li>
                </ul>
            </section>
            <div className="entrance_rubric entrance_rubric--partition1">
                <section className="rubric__grid container">
                    <h2>Раздвижные</h2>
                    <div className="rubric__text">
                        <p>Межкомнатная раздвижная перегородка с одностворчатой дверью подойдет для небольшого дверного проема. При этом сэкономит пространство комнаты — дверь не требует места при открывании. Из дополнительных плюсов: створка не открывается от сквозняка, легко скользит - не требует дополнительных усилий для закрывания.</p>
                        <p className="rubric__text_img"><img src={partition_7} alt="Межкомнатная раздвижная перегородка с одностворчатой дверью" title="Межкомнатная раздвижная перегородка с одностворчатой дверью подойдет для небольшого дверного проема" /></p>
                        <p>В двухстворчатой две створки двигаются в разные стороны. Используются для широких проемов в гостиных, столовых, террасах и гардеробных.</p>
                        <p className="rubric__text_img"><img src={partition_8} alt="Межкомнатная раздвижная перегородка с двухстворчатой дверью" title="В двухстворчатой две створки двигаются в разные стороны" /></p>
                    </div>
                    <img src={partition_6} className="rubric__img" alt="Раздвижные перегородки" title="Раздвижные перегородки" />
                    <Link to="/razdvizhnye-peregorodki/varianty-ostekleniya" className="rubric__btn">Перейти</Link>
                </section>
            </div>
            <section className="entrance_rubric entrance_rubric--partition2 container">
                <h2>Телескопические</h2>
                <p>Телескопическую раздвижную систему еще называют каскадной. В такой конструкции могут быть до трех дверей, двигающихся в одну или в разные стороны. Первую дверь, которая тянет остальные называют ведущей, остальные, которые следуют за первой — ведомые. Система работает как с синхронизатором, когда все створки открываются одновременно, так и без него.</p>
                <p>Принцип работы: при движении ведущая створка тянет за собой следующую. Такой вариант может использоваться для входного проема, разделения комнат или организации гардеробной.</p>
                <p>В телескопической двухстворчатой двери створки расположены с одной стороны. Ведущая тянет за собой вторую, открывание происходит в одну сторону. Такая конструкция подойдет для дверного проема шириной до 190 см.</p>
                <p>В телескопической четырехстворчатой двери 4 двигающихся полотна. По два с противоположных сторон. Ведущая створка тянет за собой следующую. Система хорошо смотрится в широких проемах, в гардеробных.</p>
                <p>Телескопическая двухстворчатая с одной неподвижной створкой по сути имеет три полотна. Два из них двигаются друг за другом, а третье остается неподвижным. Такой перегородкой можно сузить дверной проем, сделать его визуально более эстетичным, при этом оставить широкий проход. Применяется в дизайнерских решениях для входной группы комнат или в зонировании пространства.</p>
                <p>Телескопическая четрыхстворчатая дверь раздвигается в разные стороны, может быть со всеми подвижными полотнами, либо только с двумя - двигается только ведущая дверь. Таким вариантом можно закрыть место хранения одежды и организовать большой шкаф.</p>
                <ul className="partition_scheme_list">
                    <li>
                        <img src={partition_9} alt="Телескопическая 4-створчатая" title="Телескопическая 4-створчатая дверь" />
                        Телескопическая 4-створчатая дверь
                    </li>
                    <li>
                        <img src={partition_10} alt="Телескопическая 2-створчатая дверь" title="Телескопическая 2-створчатая дверь" />
                        Телескопическая 2-створчатая
                    </li>
                    <li>
                        <img src={partition_11} alt="Телескопическая 2-створчатая с одной неподвижной створкой" title="Телескопическая 2-створчатая дверь с одной неподвижной створкой" />
                        Телескопическая 2-створчатая<br /> с одной неподвижной створкой
                    </li>
                    <li>
                        <img src={partition_12} alt="Телескопическая 4-створчатая (двигается только в проёме)" title="Телескопическая 4-створчатая дверь (двигается только в проёме)" />
                        Телескопическая 4-створчатая<br /> (двигается только в проёме)
                    </li>
                    <li>
                        <img src={partition_13} alt="Телескопическая 4-створчатая с двумя неподвижными полотнами" title="Телескопическая 4-створчатая дверь с двумя неподвижными полотнами" />
                        Телескопическая 4-створчатая<br /> с двумя неподвижными полотнами
                    </li>
                </ul>
                <ul className="rubric__list rubric__list--item5">
                    <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_14} alt="Телескопические перегородки 1" title="Телескопические перегородки" /></Link></li>
                    <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_15} alt="Телескопические перегородки 2" title="Телескопические перегородки" /></Link></li>
                    <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_16} alt="Телескопические перегородки 3" title="Телескопические перегородки" /></Link></li>
                    <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_17} alt="Телескопические перегородки 4" title="Телескопические перегородки" /></Link></li>
                    <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_18} alt="Телескопические перегородки 5" title="Телескопические перегородки" /></Link></li>
                </ul>
                <Link to="/razdvizhnye-peregorodki/varianty-ostekleniya" className="rubric__btn">Перейти</Link>
            </section>
            <div className="entrance_rubric entrance_rubric--white2 entrance_rubric--partition3">
                <section className="container">
                    <h3 className="h2">Механизмы в раздвижных перегородках</h3>
                    <p>Полотно толще 45 мм. Внутри находятся шумопоглощающие материалы, например, древесно-волокнистая плита. Или же такая дверь изготавливается из нескольких листов ДСП, между которыми установлена агломерированная пробка.</p>
                    <ul className="interiordoors_list">
                        <li>
                            <p>Трека — верхней направляющей, по которой перемещается створка с роликами;</p>
                        </li>
                        <li>
                            <p>Нижнего направляющего элемента, который предотвращает раскачивание.</p>
                        </li>
                    </ul>
                    <p>Все системы беспороговые, это значит — на полу не будет дополнительных элементов, проход остается беспрепятственным. Основные различия механизмов: в нагрузке, которую они способны выдержать, и принципах крепления.</p>
                    <p className="center">
                        <img src={partition_19} alt="Механизмы в раздвижных перегородках" title="Механизмы в раздвижных перегородках" />
                    </p>
                    <p>В открытом механизме трек и каретка ничем не закрыты. Элементы выглядят аккуратно, эстетично и стильно, поэтому подойдут для современных интерьеров.</p>
                    <p>Механизмы с доводчиком всегда закрытого типа. Этот вариант обеспечивает плавное закрывание/открывание. Можно устанавливать в верхний торец двери, в рельсу или в ролик, с доводом в одну сторону или в две.</p>
                    <p>Механизм синхронного открывания подойдет для перегородок, которые разъезжаются в разные стороны. Открывая одну створку одновременно раскрывается вторая: помимо роликов и рельс в такой системе есть трос-синхронизатор. Вариант может быть как с доводчиком, так и без.</p>
                    <p>Раздвижной механизм скрытого типа позволяет скрыть все технические элементы. Относится к премиальным. К стене прикрепляется неподвижный ролик-каретка, на котором держится основная нагрузка, направляющие находятся на двери. Дверь навешивается на ролик, создается ощущение «парящей» двери, поэтому такую систему еще называют словом «magic» - «волшебство». Внизу у системы есть поддерживающие ролики, чтобы движение было ровным. Такой механизм чаще всего используется в одинарной или двойной раздвижной двери.</p>
                    <p>Телескопический тип позволяет открывать в одну сторону более одного полотна. Двери подвешиваются на несколько параллельных направляющих и соединяются телескопическим механизмом. Можно добавить элементы синхронного открывания или доводчик.</p>
                    <ul className="rubric__list">
                        <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_20} alt="Механизмы в раздвижных перегородках 1" title="Механизмы в раздвижных перегородках" /></Link></li>
                        <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_21} alt="Механизмы в раздвижных перегородках 2" title="Механизмы в раздвижных перегородках" /></Link></li>
                        <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_22} alt="Механизмы в раздвижных перегородках 3" title="Механизмы в раздвижных перегородках" /></Link></li>
                        <li><Link to="/razdvizhnye-peregorodki/varianty-ostekleniya"><img src={partition_23} alt="Механизмы в раздвижных перегородках 4" title="Механизмы в раздвижных перегородках" /></Link></li>
                    </ul>
                    <Link to="/razdvizhnye-peregorodki/varianty-ostekleniya" className="rubric__btn">Перейти</Link>
                </section>
            </div>
            <section className="entrance_rubric entrance_rubric--partition4 container">
                <h3 className="h2">Профили и заполнение</h3>
                <p>При изготовлении раздвижных перегородок используется алюминиевый профиль. Он может быть разного цвета: стилизован под дерево, окрашен или покрыт оксидной пленкой. Это создает возможность подобрать вариант, подходящий для любых дизайнерских идей. Створки раздвижных перегородок можно заполнить стеклом или акрилом, различными видами ДСП и МДФ или сделать зеркальными.</p>
                <div className="partition_slider">
                <Swiper
					spaceBetween={20}
					modules={[Navigation]}
					loop={true}
					autoplay={{
						disableOnInteraction: false,
					}}
					navigation={{
						nextEl: '#next',
						prevEl: '#prev',
					}}
					breakpoints={{
						420: {
							slidesPerView: 2,
						},
						600: {
							slidesPerView: 4,
						},
						768: {
							slidesPerView: 5,
							spaceBetween: 90
						},
					}}
					>
					{sliderImages.map((el, i) => {
						return (
						<SwiperSlide key={i}>
							<div className="partition_slider__item">
								<img alt={`Профили вариант ${i}`} src={el} title="Профили и заполнение" />
							</div>
						</SwiperSlide>
						)
					})}
					</Swiper>
					<div className="swiper-button-prev" id="prev"></div>
					<div className="swiper-button-next" id="next"></div>
                </div>
                <p>Комфорт + предлагает различные раздвижные перегородки в Орле, в том числе со вставками из цельного стекла или зеркала, достаточно прийти в наш офис и посмотреть варианты.</p>
                <ul className="partition_list">
                    <li>
                        <img src={partition_list_1} alt="Матовое стекло" title="раздвижные перегородки: Матовое стекло" />
                        <p>Матовое стекло</p>
                    </li>
                    <li>
                        <img src={partition_list_2} alt="Стекло бронза Мору" title="раздвижные перегородки:Стекло бронза Мору" />
                        <p>Стекло бронза Мору</p>
                    </li>
                    <li>
                        <img src={partition_list_3} alt="Зеркало" title="раздвижные перегородки: Зеркало" />
                        <p>Зеркало</p>
                    </li>
                    <li>
                        <img src={partition_list_4} alt="Черное прозрачное стекло" title="раздвижные перегородки: Черное прозрачное стекло" />
                        <p>Черное прозрачное стекло</p>
                    </li>
                    <li>
                        <img src={partition_list_5} alt="Серое прозрачное стекло" title="раздвижные перегородки: Серое прозрачное стекло" />
                        <p>Серое прозрачное стекло</p>
                    </li>
                    <li>
                        <img src={partition_list_6} alt="Акрил прозрачный" title="раздвижные перегородки: Акрил прозрачный" />
                        <p>Акрил прозрачный</p>
                    </li>
                    <li>
                        <img src={partition_list_7} alt="Акрил молочный" title="раздвижные перегородки: Акрил молочный" />
                        <p>Акрил молочный</p>
                    </li>
                    <li>
                        <img src={partition_list_8} alt="Черное матовое стекло" title="раздвижные перегородки: Черное матовое стекло" />
                        <p>Черное матовое стекло</p>
                    </li>
                    <li>
                        <img src={partition_list_9} alt="Стекло Thela" title="раздвижные перегородки: Стекло Thela" />
                        <p>Стекло Thela</p>
                    </li>
                    <li>
                        <img src={partition_list_10} alt="Стекло мастер линге" title="раздвижные перегородки: Стекло мастер линге" />
                        <p>Стекло мастер линге</p>
                    </li>
                </ul>
                <Link className="rubric__btn" to='/razdvizhnye-peregorodki/varianty-ostekleniya'>Перейти</Link>
            </section>
            <div className="container entrance_text">
                <p>
                    Наши специалисты помогут выбрать и купить раздвижную стеклянную перегородку, подходящую вашему помещению и с нужным механизмом. Они подскажут наиболее эффективный вариант, также вы можете получить подробную консультацию по телефону <a href="tel:+74862632340">(4862) 63-23-40</a>.
                </p>
            </div>
        </>
    )
}
export default PartitionPage;