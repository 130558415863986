import { useEffect, useState } from 'react';
import HeadTags from '../../shared/HeadTags';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useGetSubProductsListQuery } from 'services/api/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import CatalogListItem from "features/Catalog/CatalogListPage/CatalogListItem";
import interiordoors_1 from 'assets/images/interiordoors_1.jpg';
import interiordoors_2 from 'assets/images/interiordoors_2.jpg';
import interiordoors_21 from 'assets/images/interiordoors_21.png';
import whatsappImg from 'assets/images/whatsapp.svg';
import telegramImg from 'assets/images/telegram.svg';
import ym from 'react-yandex-metrika';
import map_img from 'assets/images/map_img.jpg';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const InteriorDoorsPage = () => {
    const navigate = useNavigate();    
    const slug = window.location.pathname;   
    const { data: categoriesList, isLoading } = useGetSubProductsListQuery(slug);
    const [itemsList, setItemsList] = useState({}); 
    
    useEffect(() => {
        ym('hit', `/mezhkomnatnye-dveri`);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    useEffect(() => {
        let list = {};
        if (categoriesList) {
            categoriesList.map((category) => {
                list[category.subcategory_slug] = category.products;
            });
            setItemsList(list);            
        }        
	}, [categoriesList]);  
    
    return (
        <>
            <HeadTags
                title='Купить межкомнатные двери с доставкой и установкой в Орле - компания Комфорт+'
                metaDescription='В Комфорт + в наличии более 1000 моделей межкомнатных дверей стандартных размеров. Со склада до вашего дома, понравившийся вариант доставят за 1 день. Работаем напрямую с 30 фабриками: большой выбор моделей от простых до элитных.'
            />
            <div className="entrance_top">
                <section className="container">
                    <h1>Межкомнатные двери</h1>
                    <p>В Комфорт + в наличии более 1000 моделей межкомнатных дверей стандартных размеров. Со склада до вашего дома, понравившийся вариант доставят за 1 день. Работаем напрямую с 30 фабриками: большой выбор моделей от простых до элитных. В наличии глухие двери — без дополнительных вставок из стекла, прозрачных решеток, пластика и других материалов, а также варианты с остеклением: зеркальными элементами, с рисунком на стеклах, шелкографией, триплексом, матовые и глянцевые. Кроме дверей в наличии, в Комфорт + можно заказать полотна нестандартных размеров шириной до 100 см, высотой до 2300 см. О том, какие еще варианты межкомнатных дверей купить в нашем магазине читайте далее.</p>
                    <div className="rubric_links rubric_links--interiordoors">
                        <Link to="/mezhkomnatnye-dveri/shponirovannye" className="rubric_links__item">Шпонированные двери</Link>
                        <Link to="/mezhkomnatnye-dveri/ekoshpon" className="rubric_links__item">Экошпон</Link>
                        <Link to="/mezhkomnatnye-dveri/dveri-iz-massiva" className="rubric_links__item">Двери из массива</Link>
                        <Link to="/mezhkomnatnye-dveri/kompozitnye-dveri" className="rubric_links__item">Влагостойкие двери</Link>
                        <Link to="/mezhkomnatnye-dveri/dveri-emal" className="rubric_links__item">Двери эмаль</Link>
                        <Link to="/mezhkomnatnye-dveri/dveri-invisible" className="rubric_links__item">Двери Invisible</Link>
                        <Link to="/mezhkomnatnye-dveri/arki-mezhkomnatnye" className="rubric_links__item">Арки</Link>
                    </div>                    
                </section>
            </div>
            <section className="entrance_rubric container">
                <h2>Классика, модерн, инвизибл (скрытые двери)</h2>
                <div className="rubric__block">
                    <img src={interiordoors_1} alt="Классические межкомнатные двери" title="Классические межкомнатные двери" />
                    <div className="rubric__block_text">
                        <p>Классические межкомнатные двери стилизованы под дерево светлых и темных оттенков. Встречаются варианты с резьбой: с серебряным или золотым покрытием, вставками из стекла: обычного, матового или с узорами. Классические двери подойдут для строгого и элегантного интерьера комнат.</p>
                        <p>Стиль модерн - простой, лаконичный и функциональный. Такие межкомнатные двери чаще всего с гладкими поверхностями, для декора используются вставки из стекла, обычно матового, в дизайне есть четкие линии. Цветовая гамма спокойных оттенков: белого, бежевого, коричневого.</p>
                        <Link to="/mezhkomnatnye-dveri/shponirovannye" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>
                    </div>
                </div>
                <div className="rubric__block">
                    <div className="rubric__block_text">
                        <p>Двери инвизибл или скрытые двери — это вариант сделать проем и сам короб незаметными. Установка межкомнатных дверей инвизибл проходит до финишной отделки помещения. Само полотно полностью повторяет цвет, дизайн и фактуру стены. Для того, чтобы дверная группа и комната выглядели единым целым используются скрытые петли и специально сконструированные рамы. Здесь важно определиться с типом двери.</p>
                        <p>Ручки в дверях инвизибл часто бывают скрытыми — в виде вертикального разреза. Именно такую модель часто делают раздвижной: она может двигаться вдоль стены или въезжать в нее.<br />У скрытых дверей много преимуществ: они не занимают много места, подходят для нестандартных проемов, за счет одинакового вида со стенами визуально увеличивают комнату, смотрятся стильно в любом интерьере.</p>
                    </div>
                    <img src={interiordoors_2} alt="Двери инвизибл" title="скрытые двери" />
                </div>
                <ul className="interiordoors_list">
                    <li>
                        <p>Под отделку, полотна продаются загрунтованными, после установки их красят, клеят обои или наносят декоративную штукатурку.</p>
                    </li>
                    <li>
                        <p>Готовые к монтажу - двери окрашивают на производстве, цвета соответствуют стандартам RAL, могут быть матовыми или глянцевыми, выполненными из каленого стекла, зеркала или МДФ.</p>
                    </li>
                </ul>
                <Link to="/mezhkomnatnye-dveri/shponirovannye" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>            
            </section>
            <div className="entrance_rubric entrance_rubric--white2">
                <section className="container">
                    <h2>Шпонированные двери, экошпон,<br /> пленка ПВХ, двери из массива</h2>
                    <p>Шпонированные двери — выгодная альтернатива полотнам из цельного массива. Шпон — срез дерева, обычно дуба, ясеня или ольхи, толщиной не больше 10 мм. Такой лист приклеивается на дверь, шлифуется, пропитывается составами и лакируется. Шпонированные варинты подойдут для классического или современного интерьера. Спокойные естественные цвета впишутся в стили лофт, кантри или барокко.</p>
                    {
                        itemsList['shponirovannye'] ?
                            <div className="rubric_slider">
                                <Swiper
                                    spaceBetween={20}
                                    modules={[Navigation]}
                                    loop={true}
                                    autoplay={{
                                        disableOnInteraction: false,
                                    }}
                                    navigation={{
                                        nextEl: '#next1',
                                        prevEl: '#prev1',
                                    }}
                                    breakpoints={{
                                        380: {
                                            slidesPerView: 2,
                                        },
                                        480: {
                                            slidesPerView: 3,
                                        },
                                        640: {
                                            slidesPerView: 4,
                                        },
                                        900: {
                                            slidesPerView: 5,
                                            spaceBetween: 50
                                        },
                                    }}
                                >
                                {                          
                                    itemsList['shponirovannye'].length
                                        ? itemsList['shponirovannye'].map((product, i) => {
                                            return (
                                                <SwiperSlide key={i}>
                                                    <CatalogListItem key={product.id} product={product} />
                                                </SwiperSlide>
                                            )
                                        }): 'Загрузка ...'
                                }
                                </Swiper>
                                <div className="swiper-button-prev" id="prev1"></div>
                                <div className="swiper-button-next" id="next1"></div>
                            </div>
                        : null
                    }                    
                    <Link to="/mezhkomnatnye-dveri/shponirovannye" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>
                    <p>Двери из экошпона недорогие за счет ненатурального покрытия. Приставка эко означает, что материал искусственного происхождения, точно повторяет цвет и фактуру натурального дерева. Экошпон производят из полипропилена - это безопасное покрытие с хорошими эксплуатационными качествами: устойчивое к незначительным повреждениям, к грибку и плесени. По дизайну двери из экошпона похожи на шпонированные.</p>
                    {
                        itemsList['ekoshpon'] ?
                            <div className="rubric_slider">
                            <Swiper
                                spaceBetween={20}
                                modules={[Navigation]}
                                loop={true}
                                autoplay={{
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    nextEl: '#next2',
                                    prevEl: '#prev2',
                                }}
                                breakpoints={{
                                    380: {
                                        slidesPerView: 2,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    640: {
                                        slidesPerView: 4,
                                    },
                                    900: {
                                        slidesPerView: 5,
                                        spaceBetween: 50
                                    },
                                }}
                            >
                            {                               
                                itemsList['ekoshpon'].length
                                    ? itemsList['ekoshpon'].map((product, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <CatalogListItem key={product.id} product={product} />
                                            </SwiperSlide>
                                        )
                                    }) : 'Загрузка ...'                                
                            }
                            </Swiper>
                            <div className="swiper-button-prev" id="prev2"></div>
                            <div className="swiper-button-next" id="next2"></div>
                        </div>
                        : null
                    }
                    
                    <Link to="/mezhkomnatnye-dveri/ekoshpon" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>
                    <p>Дверь с ПВХ пленкой — это полотно, покрытое поливинилхлоридом. Материал безопасный, выполняющий защитную и декоративную функции. Такие двери недорогие, а качественное покрытие хорошо имитирует натуральные материалы. Пленка ПВХ разнообразна по цвету и фактуре, не боится контакта с влажным воздухом, поэтому такие двери влагостойкие - их можно устанавливать в ванной.</p>
                    <p>Двери из массива — самые дорогие, относятся к категории элитных. Полотна изготавливают из древесины твердых сортов, часто ценных пород: дуб, бук, ясень. Во время изготовления массив просушивают, создают каркас. Двери получаются прочные и износостойкие. Главная особенность — меньше вариантов декора и фактур. Узнать цену межкомнатных дверей в Орле из массива можно у консультантов Комфорт+.</p>
                    {
                        itemsList['dveri-iz-massiva'] ? 
                            <div className="rubric_slider">
                                <Swiper
                                    spaceBetween={20}
                                    modules={[Navigation]}
                                    loop={true}
                                    autoplay={{
                                        disableOnInteraction: false,
                                    }}
                                    navigation={{
                                        nextEl: '#next3',
                                        prevEl: '#prev3',
                                    }}
                                    breakpoints={{
                                        380: {
                                            slidesPerView: 2,
                                        },
                                        480: {
                                            slidesPerView: 3,
                                        },
                                        640: {
                                            slidesPerView: 4,
                                        },
                                        900: {
                                            slidesPerView: 5,
                                            spaceBetween: 50
                                        },
                                    }}
                                >
                                {                                   
                                    itemsList['dveri-iz-massiva'].length
                                        ? itemsList['dveri-iz-massiva'].map((product, i) => {
                                            return (
                                                <SwiperSlide key={i}>
                                                    <CatalogListItem key={product.id} product={product} />
                                                </SwiperSlide>
                                            )
                                        }): 'Загрузка ...'                                    
                                }
                                </Swiper>
                                <div className="swiper-button-prev" id="prev3"></div>
                                <div className="swiper-button-next" id="next3"></div>
                            </div>
                        : null
                    }
                    
                    <Link to="/mezhkomnatnye-dveri/dveri-iz-massiva" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>
                </section>
            </div>
            <section className="rubric__grid entrance_rubric entrance_rubric--interiordoors container">
                <h2>Двери композитные (влагостойкие)</h2>
                <div className="rubric__text">
                    <p>Такие двери внутри заполняются пенополистиролом. Материал с хорошими теплоизоляционными свойствами. По периметру полотна есть уплотнитель, благодаря чему дверь закрывается плотно, а значит создается хорошая тепло и звукоизоляция. Композитные материалы, соответственно и межкомнатные двери - влагостойкие, они не деформируются, не разбухают и не рассыхаются. На них не образуется грибок, они хорошо моются. Такие двери используются в помещениях с высокой влажностью или в неотапливаемых комнатах, например, на даче. При этом, надо учитывать, что композитные полотна рекомендуют устанавливать только внутри помещения, чтобы исключить попадания прямых солнечных лучей.<br />Влагостойкие композитные двери можно выбрать со вставками из стекла, декоративным покрытием, разного цвета, с ламинацией, сделать армированными. Размеры элементов на полотне подбираются индивидуально.</p>
                </div>
                <img src={interiordoors_21} className="rubric__img" alt="Двери композитные (влагостойкие)" title="Двери композитные (влагостойкие)" />
                {
                    itemsList['kompozitnye-dveri'] ? 
                        <div className="rubric_slider">
                            <Swiper
                                spaceBetween={20}
                                modules={[Navigation]}
                                loop={true}
                                autoplay={{
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    nextEl: '#next4',
                                    prevEl: '#prev4',
                                }}
                                breakpoints={{
                                    380: {
                                        slidesPerView: 2,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    640: {
                                        slidesPerView: 4,
                                    },
                                    900: {
                                        slidesPerView: 5,
                                        spaceBetween: 50
                                    },
                                }}
                            >
                            {                                
                                itemsList['kompozitnye-dveri'].length
                                    ? itemsList['kompozitnye-dveri'].map((product, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <CatalogListItem key={product.id} product={product} />
                                            </SwiperSlide>
                                        )
                                    }): 'Загрузка ...'                                
                            }
                            </Swiper>
                            <div className="swiper-button-prev" id="prev4"></div>
                            <div className="swiper-button-next" id="next4"></div>
                        </div> 
                    : null
                }               
                <Link to="/mezhkomnatnye-dveri/kompozitnye-dveri" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>
            </section>
            <div className="entrance_rubric entrance_rubric--white2">
                <section className="container">
                    <h3 className="h2">Арка межкомнатная</h3>
                    <p>Комфорт + предлагает арки межкомнатные в Орле. Это вариант для зонирования или декорирования помещения. Дверной проем выглядит стильно и может стать частью интерьера: классического, в стиле хайтек или сканди. Арки чаще всего выполняют из шпона или экошпона. Сейчас популярны порталы - это обрамление проема П-образной формы. В таком варианте могут сочетаться несколько цветов, резьба, стеклянные вставки.</p>
                    {
                    itemsList['arki-mezhkomnatnye'] ?
                        <div className="rubric_slider">
                            <Swiper
                                spaceBetween={20}
                                modules={[Navigation]}
                                loop={true}
                                autoplay={{
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    nextEl: '#next5',
                                    prevEl: '#prev5',
                                }}
                                breakpoints={{
                                    380: {
                                        slidesPerView: 2,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    640: {
                                        slidesPerView: 4,
                                    },
                                    900: {
                                        slidesPerView: 5,
                                        spaceBetween: 50
                                    },
                                }}
                            >
                            {                                
                                itemsList['arki-mezhkomnatnye'].length
                                    ? itemsList['arki-mezhkomnatnye'].map((product, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <CatalogListItem key={product.id} product={product} />
                                            </SwiperSlide>
                                        )
                                    }): 'Загрузка ...'                          
                            }
                            </Swiper>
                            <div className="swiper-button-prev" id="prev5"></div>
                            <div className="swiper-button-next" id="next5"></div>
                        </div>
                    : null
                    }                    
                    <Link to="/mezhkomnatnye-dveri/arki-mezhkomnatnye" className="rubric__btn rubric__btn--big">Перейти в каталог дверей</Link>
                </section>
            </div>            
            <section className="container entrance_contacts">
                <h3 className="h2">Магазин межкомнатных дверей</h3>
                <div className="entrance_map">
                    <YMaps>
                        <Map
                            state={{
                                center: [52.966997, 36.077501],
                                zoom: 16,
                            }}
                            width={'100%'}
                            height={'100%'}
                            options={{autoFitToViewport: 'always'}}
                        >
                            <Placemark
                                geometry={[52.966997, 36.077501]}
                                options={{
                                    preset: "islands#blueIcon",
                                }}
                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                properties={{
                                    iconCaption: 'Комфорт+',
                                    baloonContent: 'baloonContent',
                                    hintContent:
                                        `<div class="map-img"><img src="${map_img}"></div>`,
                                    }}
                            />
                        </Map>
                    </YMaps>
                </div>
            </section>
            <div className="container entrance_text">
                <p>
                    Специалисты компании помогут купить межкомнатные двери в Орле, выполнят доставку и установку.<br /> Подробную консультацию можно получить по телефону 
                    <span className="phones-row phones-row--inline">&nbsp;
                        <a href="tel:+79300632340">+7 (930) 063-23-40</a>
                        <a className="messenger" href="https://wa.me/79300632340" target="_blank"><img src={whatsappImg} alt="Whatsapp" width="20" /></a>
                        <a className="messenger" href="https://t.me/+79300632340" target="_blank"><img src={telegramImg} alt="Telegram" width="20" /></a>
                    </span>
                </p>
            </div>
        </>
    )
}
export default InteriorDoorsPage;