import { Link } from "react-router-dom";
import { useState } from "react";
import { useCreateCallFormRequestMutation } from "services/api/api";
import { FormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import ClientCaptcha from "react-client-captcha";

const AskModal = ({setShow} : {setShow: (boolean) => void}) => {
	const [captchaCode, setCaptcha] = useState();
	const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
	const [createCallFormRequest] = useCreateCallFormRequestMutation();
	const phoneNumberMask = [
		"+",
		"7",
		"(",
		/\d/,
		/\d/,
		/\d/,
		")",
		/\d/,
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/
	];
	const validationsSchema = yup.object().shape({
		name: yup.string()
			.min(3, 'Слишком короткое имя')
			.matches(
				/[а-я,А-Я]/,
				'Имя должно содержать только русские буквы'
			)
			.matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
			.required('Обязательно к заполнению'),
		phone: yup.string()
			.required('Обязательно к заполнению')
			.transform(value => value.replace(/[^\d]/g, ''))
			.min(11, "Введите корректный номер"),
		check: yup.bool()
			.oneOf([true], 'Дайте согласие на обработку персональных данных'),
		captcha: yup.string()
			.oneOf([captchaCode, null], 'Неверный код')
			.required('Обязательно к заполнению'),
		address: yup.string()
			.required('Обязательно к заполнению')
			.test(
				'nourl',
				'Текст сообщения введен некорректно',
				(value) => {
					if (!value) {
						return true;
					}
					try {
						return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
					} catch {
						return false;
					}
				}
			)
	});
	return (
		<>
			{resultShow === false 
				? 	<div className="popup">
						<p className="popup__title">Вызвать замерщика</p>
						<Formik
							initialValues={{
								name: '',
								phone: '',
								address: '',
								captcha: '',
								check: true
							}}
							validateOnBlur
							onSubmit={async ({name, phone, address, captcha}, {resetForm}) => { 
								const body: FormRequest = {
									name,
									phone,
									address, 
									form_source_url: `${window.location}`
								};
								let res = await createCallFormRequest({body});
								if (res['error']) {
									setResultShow(true);
									setResultText({
										status: 'Ошибка!',
										text: `Попробуйте позже`
									});
								} else {
									resetForm();
									setResultShow(true);
									setResultText({
										status: 'Спасибо!',
										text: `Ваша заявка отправлена`
									});

								}
							}}
							validationSchema={validationsSchema}
						>
							{({
								handleSubmit,
								isSubmitting
							}) => (
							<Form onSubmit={handleSubmit} className="form">
								<div className="form__group">
									<Field
										className="form__entry"
										type="text"
										name="name"
										placeholder="Имя*" />
									<ErrorMessage name="name" component="span" className="form__error" />
								</div>
								<div className="form__group">
									<Field name="phone">
										{({field}) => <MaskedInput
											{...field}
											mask={phoneNumberMask}
											id="phone"
											placeholder="Телефон*"
											type="text"
											className="form__entry"
											/>
										}
									</Field>
									<ErrorMessage name="phone" component="span" className="form__error" />
								</div>
								<div className="form__group">
									<Field
										as="textarea"
										name="address"
										className="form__entry"
										placeholder="Адрес *"/>
									<ErrorMessage name="address" component="span" className="form__error" />
								</div>
								<div className="form__group form__captcha">
									<ClientCaptcha
										captchaCode={setCaptcha}
										chars="1234567890"
										charsCount={4}
										width={80}
										height={35}
										fontSize={20}
										backgroundColor="#3c3c3b"
										fontColor="lightgray"
									/>
									<Field
										className="form__entry"
										type="text"
										name="captcha"
										placeholder="Введите код с картинки" />
									<ErrorMessage name="captcha" component="span" className="form__error" />
								</div>
								<div className="form__group form__check">
									<Field 
										className="visually-hidden"
										type="checkbox" 
										name="check" />
									<span>Отправляя заявку, вы соглашаетесь с <a href={process.env.PUBLIC_URL + '/politic.pdf'} target="_blank" rel="noreferrer">Положением об обработке и защите персональных данных</a></span> 
									<ErrorMessage name="check" component="small" className="form__error" />
								</div>  
								<button className="form__btn" type="submit" disabled={isSubmitting}>Отправить</button> 
							</Form>
							)}   
						</Formik>  
					</div>
				: 	<FormResult status={resultText.status} text={resultText.text}/>
			}
		</>
		
	)
};
export default AskModal;