import { useState } from 'react';
import { useGetCookie } from 'app/hooks';

const GdprModal = () => {
	const [isShow, setIsShow] = useState<boolean>(true);
	const notice = useGetCookie("notice");
	const handleClick = () => {
		const date = new Date();
		date.setDate(date.getDate() + 1);
		document.cookie = "notice=no; path=/; expires=" + date.toUTCString();
		setIsShow(false);
	}
	return (
		<>
			{(notice !== "no") && isShow &&
                <div className="gdpr gdpr--white">
                    <div className="container">
                        <p>На этом сайте мы используем cookie-файлы. Вы можете прочитать о cookie-файлах или изменить настройки браузера. 
                            Продолжая пользоваться сайтом без изменения настроек, вы даете согласие на использование ваших cookie-файлов. 
                            <small>Все собранные при помощи cookie-файлов данные будут храниться на территории РФ.</small></p>
                        <small>Все собранные при помощи cookie-файлов данные будут храниться на территории РФ.</small>	    
                        <button type="button" id="gdpr_button" className="gdpr_close" title="Закрыть" onClick={handleClick}>Понятно</button>
                    </div>
                </div>                
			}
		</>
	)
};
export default GdprModal;