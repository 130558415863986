import { Link } from "react-router-dom";

const glassImg = require("assets/images/svg/glass.svg").default;

const NotFound = () => {
  return (
    <div className="error">
      <section className="container">
        <div className="error__img">
          <img src={glassImg} alt="Страница не может быть найдена" title="Страница не может быть найдена"/>
        </div>
        <h1 className="error__title">Извините! Страница не может быть найдена.</h1>
        <p>Возможно, запрашиваемая Вами страница была перенесена или удалена.<br/>Если у Вас есть вопросы, свяжитесь с нами по телефону <a href="tel:+79300632340">+7 (930) 063-23-40</a> или напишите <a href="mailto:comfortplus57@gmail.com">comfortplus57@gmail.com</a></p>
        <div className="error__links">
          <Link to="/vhodnye-dveri">Входные</Link>
          <Link to="/mezhkomnatnye-dveri">Межкомнатные двери</Link>
          <Link to="/razdvizhnye-peregorodki">Раздвижные перегородки</Link>
          <Link to="/furnitura">Фурнитура</Link>
        </div>
      </section>
    </div>
  )
};
export default NotFound;