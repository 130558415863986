import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetCategoryQuery } from '../../services/api/api';
import { Category } from '../../services/api/types/Category';

const MenuItem = ({el, url}: {el: Category, url: string}) => {
  const startUrl = `${url}/${el.slug}`;
  return (
    <li className={el.image ? 'menu__icon-link' : ''}>
      <NavLink 
        to={startUrl}  
        itemProp="url" >
          {el.image && <span className="menu__icon">
            <img src={el.image} alt={el.name} title={el.name}/>
          </span>}
          {el.name}
        </NavLink>
      {el.children.length ? 
        <ul className="menu__lvl3">
          {childrenList(el.children, startUrl)}
        </ul>
      : ''}
    </li>
  )
};
const childrenList = (data: Category[], startUrl: string): React.ReactNode => {
  return (
    <>
      {[...data].map(el => {
        return (
          <MenuItem el={el} key={el.id} url={startUrl}/>
        );
      })}
    </>
  );
};

const MenuCatalog = ({startElId, addedClass}: {startElId: number, addedClass?: string}) => {
  const {data, error, isLoading} = useGetCategoryQuery();
  const [isActive, setIsActive] = useState(false);
  const startEl = data.categories && data.categories.filter(el => el.id === startElId)[0];
  const menuData = startEl.children;
  const startUrl = `/${startEl.slug}`;

  return (
    <>
      <span className="menu__arrow" onClick={() => setIsActive(true)}></span>
      <ul className={`menu__lvl2 ${isActive?'active':''} ${addedClass}`} itemScope itemType="http://schema.org/SiteNavigationElement">
        {error ? (
          <>Ошибка</>
        ) : isLoading ? (
          <>Загрузка...</>
        ) : data ? (
          childrenList(menuData, startUrl)
        ) : null}
        <li className="menu__back" onClick={() => setIsActive(false)}>{startEl.name}</li>
      </ul>
    </>
  );
};

export default MenuCatalog;