import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams, useLocation } from "react-router-dom";
import { useGetProductsListQuery } from 'services/api/api';
import AsideMenu from "features/Catalog/AsideMenu";
import LoadingBlock from 'shared/LoadingBlock';
import CatalogListItem from "features/Catalog/CatalogListPage/CatalogListItem";
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";

const SearchResultPage = () => {
	const { keyword } = useParams();
	const [searchParams, setSearchParams] = useSearchParams()
	const [limit, setLimit] = useState<number>(24);
	const [showMore, setShowMore] = useState(false);
	const [page, setPage] = useState<number>(Number(searchParams.get("page")) || 1);
	const [offset, setOffset] = useState<number>((page - 1) * limit);
	const { data, isLoading } = useGetProductsListQuery({ name: keyword, limit, offset });
	const [itemsList, setItemsList] = useState([]);

	useEffect(() => {
		setPage(1);
		setOffset(0);
	}, []);

	useEffect(() => {
		setShowMore(false);
	}, [window.location.pathname]);

	useEffect(() => {
		if (data?.results) {
			if (showMore) {
				setItemsList(state => [...state, ...data.results]);
			} else {
				setItemsList(data.results);
			}
		}
	}, [data]);

	useEffect(() => {
		setSearchParams((searchParams) => {
			if (page === 1) {
				searchParams.delete("page");
			} else {
				searchParams.set("page", page.toString());
			}
			return searchParams;
		});
		if (!showMore) {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		}
	}, [page, showMore, setSearchParams]);

	const goodList = data
		? itemsList.length
			? itemsList.map(product => {
				return (
					<CatalogListItem key={product.id} product={product} search={true} />
				)
			})
			: <li className="empty center">
				Каталог на стадии наполнения, не все товары сейчас представлены на сайте.
			</li>
		: 'Загрузка ...';

	return (
		<>
			<HeadTags
				title='Результаты поиска в интернет-магазине Универсал'
				metaDescription='Результаты поиска'
			/>
			<section className="catalog container">
				<h1>Результаты поиска: "{keyword}"</h1>
				<AsideMenu />
				<div className="catalog__content">
					<LoadingBlock isLoading={isLoading}>
						<>
							<ul className="catalog-list" itemScope itemType="http://schema.org/ItemList">
								{goodList}
							</ul>
							{data &&
								<Pagination
									additionalLoading={isLoading && showMore}
									pageSize={limit}
									currentPage={page}
									totalCount={data.count}
									onPageChange={(page, add = false) => {
										if (add) {
											setShowMore(true);
											setOffset((page - 1) * limit);
											setPage(page);
										} else {
											setShowMore(false);
											if (page === Infinity) {
												setLimit(data.count);
												setOffset(0);
												setPage(1);
											} else {
												setOffset((page - 1) * limit);
												setPage(page);
											}
										}
									}}
								/>
							}
						</>
					</LoadingBlock>
				</div>
			</section>
		</>
	)
}
export default SearchResultPage;