import { useEffect, useRef, useState } from 'react';
import HeadTags from '../../shared/HeadTags';
import { useNavigate, Link } from 'react-router-dom';
import entrance_1 from 'assets/images/entrance_1.jpg';
import entrance_2 from 'assets/images/entrance_2.jpg';
import entrance_3 from 'assets/images/entrance_3.jpg';
import entrance_4 from 'assets/images/entrance_4.jpg';
import entrance_5 from 'assets/images/entrance_5.jpg';
import entrance_6 from 'assets/images/entrance_6.jpg';
import entrance_7 from 'assets/images/entrance_7.jpg';
import entrance_8 from 'assets/images/entrance_8.jpg';
import entrance_9 from 'assets/images/entrance_9.jpg';
import entrance_10 from 'assets/images/entrance_10.png';
import entrance_11 from 'assets/images/entrance_11.jpg';
import entrance_12 from 'assets/images/entrance_12.jpg';
import entrance_13 from 'assets/images/entrance_13.jpg';
import entrance_14 from 'assets/images/entrance_14.jpg';
import entrance_15 from 'assets/images/entrance_15.jpg';
import entrance_16 from 'assets/images/entrance_16.jpg';
import entrance_17 from 'assets/images/entrance_17.jpg';
import entrance_18 from 'assets/images/entrance_18.jpg';
import ym from 'react-yandex-metrika';

const EntranceDoorsPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        ym('hit', `/vhodnye-dveri`);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);
    return (
        <>
            <HeadTags
                title='Купить входные двери с доставкой и установкой в Орле - компания Комфорт+'
                metaDescription='В магазине «Комфорт +» в наличии более 100 моделей дверей стандартных размеров. Это варианты для дверной коробки шириной 86 см и 96 см, с открыванием в правую или левую сторону. Их можно заказать в подходящем дизайне: подобрать внутреннюю отделку и нужный цвет.'
            />
            <div className="entrance_top">
                <section className="container">
                    <h1>Входные двери в Орле в наличии и на заказ</h1>
                    <p>Входная дверь в современном жилище - не просто элемент комнаты, который должен защищать от незваных гостей. Дверь должна обладать тепло и звукоизоляцией, вписываться в интерьер, быть надежной и долговечной.</p>
                    <p>В магазине «Комфорт +» в наличии более 100 моделей дверей стандартных размеров. Это варианты для дверной коробки шириной 86 см и 96 см, с открыванием в правую или левую сторону. Их можно заказать в подходящем дизайне: подобрать внутреннюю отделку и нужный цвет.</p>
                    <div className="rubric_links">
                        <Link to="/vhodnye-dveri/dveri-russidor" className="rubric_links__item">Двери по фабрикам</Link>
                        <Link to="/vhodnye-dveri/byudzhetnye-dveri" className="rubric_links__item">Бюджетные двери</Link>
                        <Link to="/vhodnye-dveri/dveri-s-termorazryvom" className="rubric_links__item">Двери с терморазрывом</Link>
                        <Link to="/vhodnye-dveri/protivopozharnye-dveri" className="rubric_links__item">Противопожарные двери</Link>
                    </div>
                    <p>Также можно купить входную дверь нестандартных размеров для частных домов или промышленных помещений высотой от 1800 до 2300 см.<br/>Вариант, подходящий для непохожего интерьера смежных комнат. Полотна с разных сторон можно сделать любых цветов и фактур, добавить отделку или зеркала.</p>
                </section>
            </div>
            <section className="entrance_rubric container">
                <h2>Бюджетные двери</h2>
                <p>Недорогие входные металлические двери производства КНР подойдут для дачи или в качестве временного варианта при строительстве дома. Невысокая цена складывается за счет массового производства и недорогих материалов. Простой дизайн, классические расцветки в коричневых и черных оттенках.</p>
                <ul className="rubric__list">
                    <li><Link to="/vhodnye-dveri/byudzhetnye-dveri"><img src={entrance_1} alt="Бюджетные двери 1" title="Бюджетные двери"/></Link></li>
                    <li><Link to="/vhodnye-dveri/byudzhetnye-dveri"><img src={entrance_2} alt="Бюджетные двери 2" title="Бюджетные двери"/></Link></li>
                    <li><Link to="/vhodnye-dveri/byudzhetnye-dveri"><img src={entrance_3} alt="Бюджетные двери 3" title="Бюджетные двери"/></Link></li>
                    <li><Link to="/vhodnye-dveri/byudzhetnye-dveri"><img src={entrance_4} alt="Бюджетные двери 4" title="Бюджетные двери"/></Link></li>
                </ul>
                <Link to="/vhodnye-dveri/byudzhetnye-dveri" className="rubric__btn">Перейти</Link>
            </section>
            <div className="entrance_rubric entrance_rubric--white">
                <section className="rubric__grid container">
                    <h2>Утепленные входные<br/> металлические двери</h2>
                    <div className="rubric__text">
                        <p>Чтобы тепло быстро не уходило из помещения, можно заказать установку входной двери с двух или трехконтурным утеплением. Контур утепления - это лента из резины или силикона, которая крепится по краям дверного проема. Благодаря вставкам дверь закрывается плотнее и мягче, обеспечивается тепло и шумоизоляция.</p>
                        <p>Двухконтурное утепление - это дверь, в которой лента клеится по периметру самого полотна и по периметру коробки. Такой вариант недорогой с хорошими показателями теплоизоляции.</p>
                        <p>При трехконтурном утеплении один уплотнитель клеится на коробку, а на само полотно - два. Такие двери лучше сохраняют тепло, но стоят дороже.</p>
                    </div>
                    <img src={entrance_5} className="rubric__img" alt="Утепленные входные металлические двери" title="Утепленные входные металлические двери"/>
                    <ul className="rubric__list">
                        <li><Link to="/vhodnye-dveri/dveri-kova"><img src={entrance_6} alt="Утепленные двери 1" title="Утепленные двери"/></Link></li>
                        <li><Link to="/vhodnye-dveri/dveri-kova"><img src={entrance_7} alt="Утепленные двери 2" title="Утепленные двери"/></Link></li>
                        <li><Link to="/vhodnye-dveri/dveri-kova"><img src={entrance_8} alt="Утепленные двери 3" title="Утепленные двери"/></Link></li>
                        <li><Link to="/vhodnye-dveri/dveri-kova"><img src={entrance_9} alt="Утепленные двери 4" title="Утепленные двери"/></Link></li>
                    </ul>
                    <Link to="/vhodnye-dveri/dveri-kova" className="rubric__btn">Перейти</Link>
                </section>
            </div>
            <section className="rubric__grid entrance_rubric entrance_rubric--gray container">
                <h2>Двери с терморазрывом</h2>
                <div className="rubric__text">
                    <p>Терморазрыв - это слой между внешним и внутренним полотном металлической двери, который не позволяет им соприкасаться, а значит обмениваться теплом. Благодаря этому дверь не промерзает, не образуется конденсат. Такие входные двери могут быть с разными видами утеплителя, листы для полотен изготавливают в основном из стали и алюминия.</p>
                </div>
                <img src={entrance_10} className="rubric__img" alt="Двери с терморазрывом" title="Двери с терморазрывом"/>
                <Link to="/vhodnye-dveri/dveri-s-termorazryvom" className="rubric__btn">Перейти</Link>
            </section>
            <div className="entrance_rubric entrance_rubric--white2">
                <section className="container">
                    <h3 className="h2">Двери с повышенной акустической изоляцией (ACOUSTIC)</h3>
                    <p>Полотно толще 45 мм. Внутри находятся шумопоглощающие материалы, например, древесно-волокнистая плита. Или же такая дверь изготавливается из нескольких листов ДСП, между которыми установлена агломерированная пробка.</p>
                    <ul className="rubric__list">
                        <li><Link to="/vhodnye-dveri/dveri-oiko"><img src={entrance_11} alt="Двери с повышенной акустической изоляцией 1" title="Двери с повышенной акустической изоляцией"/></Link></li>
                        <li><Link to="/vhodnye-dveri/dveri-oiko"><img src={entrance_12} alt="Двери с повышенной акустической изоляцией 2" title="Двери с повышенной акустической изоляцией"/></Link></li>
                        <li><Link to="/vhodnye-dveri/dveri-oiko"><img src={entrance_13} alt="Двери с повышенной акустической изоляцией 3" title="Двери с повышенной акустической изоляцией"/></Link></li>
                        <li><Link to="/vhodnye-dveri/dveri-oiko"><img src={entrance_14} alt="Двери с повышенной акустической изоляцией 4" title="Двери с повышенной акустической изоляцией"/></Link></li>
                    </ul>
                    <Link to="/vhodnye-dveri/dveri-oiko" className="rubric__btn">Перейти</Link>
                </section>
            </div>
            <section className="entrance_rubric container">
                <h3 className="h2">Противопожарные двери</h3>
                <p>Это дверь, которая сможет до 60 минут задержать распространение огня, горячего и холодного дыма из помещения. Внутри полотна находятся теплоизоляционные материалы. Их количество и последовательность, обеспечивают нужный предел огнестойкости. По периметру полотна клеится термоуплотнительная лента. Такую дверь можно дополнить специальным “окошком”. Это вариант для организаций, работающих по ночам, например, аптек.<br/>Современные противопожарные двери выполняют свою основную функцию - защищают от огня, при этом удобны в использовании и эстетичны.</p>
                <ul className="rubric__list">
                    <li><Link to="/vhodnye-dveri/protivopozharnye-dveri"><img src={entrance_15} alt="Противопожарные двери 1" title="Противопожарные двери"/></Link></li>
                    <li><Link to="/vhodnye-dveri/protivopozharnye-dveri"><img src={entrance_16} alt="Противопожарные двери 2" title="Противопожарные двери"/></Link></li>
                    <li><Link to="/vhodnye-dveri/protivopozharnye-dveri"><img src={entrance_17} alt="Противопожарные двери 3" title="Противопожарные двери"/></Link></li>
                    <li><Link to="/vhodnye-dveri/protivopozharnye-dveri"><img src={entrance_18} alt="Противопожарные двери 4" title="Противопожарные двери"/></Link></li>
                </ul>
                <Link to="/vhodnye-dveri/protivopozharnye-dveri" className="rubric__btn">Перейти</Link>
            </section>
            <div className="entrance_rubric entrance_rubric--white2">
                <section className="container">
                    <h3 className="h2">Технические двери</h3>
                    <p>Такие двери прочные, оснащены встроенным запирающим механизмом, иногда доводчиком. Чаще всего устанавливаются на предприятиях или организациях в складских и служебных помещениях. Могут быть с одной створкой или двумя. Такие входные двери изготавливаются под конкретный размер проема. Технические двери сочетают надежность конструкции, приемлемую стоимость и хорошие показатели эксплуатации.</p>
                    <Link to="/vhodnye-dveri/metallicheskie-tehnicheskie-dveri" className="rubric__btn">Перейти</Link>
                </section>
            </div>
            <div className="container entrance_text">
                <p>
                    Купить входную дверь в Орле можно в <span>Комфорт +</span>. Доставка выбранной модели занимает от одной до двух недель.<br/>При установке понравившегося варианта можно сделать дополнительный металлический добор.
                </p>
            </div>
        </>
    )
}
export default EntranceDoorsPage;