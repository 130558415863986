import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const SearchForm = () => {
	const [val, setVal] = useState<string>('');
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setVal('');
	}, [location]);

	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/search/${val}`);
	};
	const handleInputChange = (e) => {
		setVal(e.target.value.replaceAll("/", "%2f"));
	};

	return (		
        <form className="search" onSubmit={handleSubmit}>
            <input type="text" name="search" autoComplete="off" required value={val.replaceAll("%2f", "/")} onInput={handleInputChange} placeholder="Поиск"/>
            <button type="submit" className="search__btn"></button>				
        </form>
	);
};
