import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Search = ({setShow}: {setShow: (state: boolean) => void}) => {
	const [val, setVal] = useState<string>('');
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setVal('');
	}, [location]);

	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/search/${val}`);
	};
	const handleInputChange = (e) => {
		setVal(e.target.value.replaceAll("/", "%2f"));
	};

	return (
		<div className="search__wrapper">
			<form className="search container" onSubmit={handleSubmit}>
				<input type="text" name="search" autoComplete="off" required value={val.replaceAll("%2f", "/")} onInput={handleInputChange} placeholder="Поиск по каталогу"/>
				<button type="submit" className="search__btn"></button>
				<button className="search__btn search__btn--close" onClick={() => setShow(false)}></button>
			</form>
		</div>
	);
};
export default Search;
