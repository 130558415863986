import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductList } from 'services/api/types/Product';
import { api } from 'services/api/api';

type State = {
    results: ProductList['results'],
    loadMore: boolean
};

const initialState: State = {
    results: [],
    loadMore: false
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setLoadMore: (state, action: PayloadAction<boolean>) => {
            state.loadMore = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.getProductsList.matchFulfilled,
            (state, { payload }) => {
                if (state.loadMore) {
                    state.results = [...state.results, ...payload.results];
                } else {
                    state.results = payload.results;
                }
            }
        )
    },
})

export const { reducer: catalogReducer } = catalogSlice;
export const { setLoadMore } = catalogSlice.actions;
