import Helmet from "react-helmet";

const HeadTags = (props: any) => {
  const {
    title = "Двери Комфорт официальный сайт",
    metaDescription = "Широкий выбор входных и межкомнатных дверей для вашего дома или офиса. А если вдруг нужной модели не окажется в наличии или у Вас есть особые предпочтения по форме, размерам, конструкции или отделочным материалам – мы изготовим дверь по индивидуальному заказу.",
    pageURL = ''
  } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" key="title" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content="http://universal57.ru/img/logo_new.png" />  
      <meta property="og:url" content={document.URL}></meta>
      {pageURL && <link rel="canonical" href={pageURL} />}
    </Helmet>
  );
};
export default HeadTags;